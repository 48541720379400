import React, { useState, useMemo } from "react";
import LegendComponent from "../shared/components/Legend";
import strIcon from "../shared/images/stats/StatIcon_Str.webp";
import dexIcon from "../shared/images/stats/StatIcon_Dex.webp";
import defIcon from "../shared/images/stats/StatIcon_Def.webp";
import spdIcon from "../shared/images/stats/StatIcon_Spd.webp";
import LegendStatistic from "../shared/components/LegendStatistic";
import { Card, Typography } from 'antd';
import { Legend } from "../shared/types/legends.type";
import { LegendStatsEnum } from "../shared/enums/legend.enum";
import Weapon from "../shared/components/Weapon";

interface LegendProps {
    legend: Legend
}

export default function LegendPage({ legend }: LegendProps) {
    const [upgrade, setUpgrade] = useState<string>();

    const stats = useMemo(() => [
        {
            type: LegendStatsEnum.STRENGTH,
            value: legend.strength,
            icon: strIcon,
            upgrade: upgrade === LegendStatsEnum.STRENGTH,
            degrade: upgrade === LegendStatsEnum.SPEED
        },
        {
            type: LegendStatsEnum.DEXTERITY,
            value: legend.dexterity,
            icon: dexIcon,
            upgrade: upgrade === LegendStatsEnum.DEXTERITY,
            degrade: upgrade === LegendStatsEnum.DEFENSE
        },
        {
            type: LegendStatsEnum.DEFENSE,
            value: legend.defense,
            icon: defIcon,
            upgrade: upgrade === LegendStatsEnum.DEFENSE,
            degrade: upgrade === LegendStatsEnum.STRENGTH
        },
        {
            type: LegendStatsEnum.SPEED,
            value: legend.speed,
            icon: spdIcon,
            upgrade: upgrade === LegendStatsEnum.SPEED,
            degrade: upgrade === LegendStatsEnum.DEXTERITY
        }
    ], [legend, upgrade]);  // Dependencies to trigger recalculation

    return (
        <>
            <Card style={{ display: 'block', width: 315, marginInline: "auto", marginBottom: 25, textAlign: 'center' }}>
                <div style={{ display: 'flex', textAlign: 'left' }}>
                    <div style={{ marginRight: 10 }}>
                        <LegendComponent legend={legend} size={75} />
                    </div>
                    <div>
                        <Typography.Title style={{ margin: 0 }}>{legend.bio_name}</Typography.Title>
                        <Typography.Paragraph style={{ margin: 0 }}>{legend.bio_aka}</Typography.Paragraph>
                    </div>
                </div>
            </Card>
            <Card title="Weapons" style={{ display: 'block', width: 315, marginInline: "auto", marginBottom: 25 }}>
                <Weapon weapon={legend.weapon_one} style={{ width: 60, marginRight: 15 }} />
                <Weapon weapon={legend.weapon_two} style={{ width: 60 }} />
            </Card>
            <Card title="Statistics" style={{ display: 'block', width: 315, marginInline: "auto", marginBottom: 25 }}>
                {
                    stats.map((stat, index) => (
                        <LegendStatistic
                            key={index}
                            legendId={legend.legend_id}
                            type={stat.type}
                            icon={stat.icon}
                            value={stat.value}
                            onUpgrade={() => setUpgrade(stat.type)}
                            upgrade={stat.upgrade}
                            degrade={stat.degrade}
                        />
                    ))
                }
            </Card>
        </>
    );
}
