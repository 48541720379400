import {Typography} from "antd";
import React from "react";

interface UserStatProps {
    label: string,
    value: string | number
}

export default function UserStat({ label, value }: UserStatProps) {
    return (
        <div style={{ display: 'inline-block', marginRight: 25 }}>
            <Typography.Text style={{
                textTransform: 'uppercase',
                userSelect: 'none',
                fontSize: 10,
                opacity: 0.6,
                display: 'block'
            }}>{label}</Typography.Text>
            <Typography.Text style={{ userSelect: 'none' }}>{value}</Typography.Text>
        </div>
    );
}
