import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: false,
    reducers: {
        setLoading: (_, action: PayloadAction<boolean>) => {
            return action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {setLoading} = loadingSlice.actions;

export default loadingSlice.reducer;
