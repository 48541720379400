import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Legend} from "../../types/legends.type";

type MainState = {
    legends: Legend[],
    finishedLoading: boolean
}

const initialState: MainState = {
    legends: [],
    finishedLoading: false
};

export const mainSlice = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setLegends: (state, action: PayloadAction<Legend[]>) => {
            state.legends = action.payload;
        },
        setFinishedLoading: (state, action: PayloadAction<boolean>) => {
            state.finishedLoading = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {setLegends, setFinishedLoading} = mainSlice.actions;

export default mainSlice.reducer;
