import { useState, useEffect } from "react";
import { message } from "antd";
import { getPlayerStatsById, getRankedById } from "../services/brawlhalla.service";
import { PlayerRanked, PlayerStats } from "../types/legends.type";
import {useLocalStorage} from "@mralbelo/react-hooks";
import {RecentlySearchedPlayer} from "../types/local-storage.type";
import {useDispatch} from "react-redux";
import {setLoading as setLoadingReducer} from "../store/reducers/loading.reducer";

interface UsePlayerDataResult {
    ranked: PlayerRanked | null;
    stats: PlayerStats | null;
    loading: boolean;
    error: boolean;
}

export default function usePlayerData(brawlhallaId: string | undefined): UsePlayerDataResult {
    const [ranked, setRanked] = useState<PlayerRanked | null>(null);
    const [stats, setStats] = useState<PlayerStats | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [recentPlayers, setRecentPlayers] = useLocalStorage<{ [key: number]: RecentlySearchedPlayer }>(
        "recently-searched-players", {}
    );
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            dispatch(setLoadingReducer(true));
            if (!brawlhallaId) {
                message.error("Invalid ID provided.");
                setError(true);
                setLoading(false);
                dispatch(setLoadingReducer(false));
                return;
            }

            try {
                const playerStats = await getPlayerStatsById(+brawlhallaId);
                const playerRanked = await getRankedById(+brawlhallaId);
                setRecentPlayers(
                    Object.assign(recentPlayers, {
                        [playerStats.brawlhalla_id]: {
                            brawlhalla_id: playerStats.brawlhalla_id,
                            name: playerStats.name,
                            timestamp: Date.now()
                        }
                    })
                );
                setStats(playerStats);
                setRanked(playerRanked);
                setError(false);
            } catch (err) {
                message.error('Failed to fetch data. Please try again later.');
                setError(true);
            } finally {
                setLoading(false);
                dispatch(setLoadingReducer(false));
            }
        }

        fetchData().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brawlhallaId]);

    return { ranked, stats, loading, error };
}
