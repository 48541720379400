import { Popover } from 'antd';
import { LegendStats } from '../types/local-storage.type';
import {useCallback, useEffect} from "react";
import { useLocalStorage } from '@mralbelo/react-hooks';

interface StatisticProps {
    legendId: number,
    type: string,
    icon: string,
    value: number,
    size?: number,
    onUpgrade?: () => void,
    upgrade?: boolean,
    degrade?: boolean
}

export default function LegendStatistic({ legendId, type, icon, value, size = 30, onUpgrade, upgrade = false, degrade = false }: StatisticProps) {
    const [legendStats, setLegendStats] = useLocalStorage<LegendStats[]>('legend-stats', []);
    const handleUpgrade = useCallback(() => {
        if (onUpgrade) {
            const newStats = [...legendStats];
            const index = newStats.findIndex(l => l.legendId === legendId && l.type === type);
            const newStat = { legendId, type, upgrade, degrade };

            if (index > -1) {
                newStats[index] = newStat;
            } else {
                newStats.push(newStat);
            }

            setLegendStats(newStats);
            onUpgrade();
        }
    }, [legendStats, legendId, type, upgrade, degrade, onUpgrade, setLegendStats]);
    const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    const setClassNames = (index: number) => {
        let classes = 'stat-value ';
        if (index < value) {
            classes += degrade && index === value - 1 ? 'degrade' : 'active';
        } else {
            classes += upgrade && index === value ? 'upgrade' : 'inactive';
        }
        return classes;
    };

    useEffect(() => {
        if (!legendStats) return;
        const index = legendStats.findIndex(l => l.legendId === legendId && l.type === type);
        if (index !== -1) {
            const stat = legendStats[index];
            if (stat.upgrade !== upgrade || stat.degrade !== degrade) {
                handleUpgrade();
            }
        }
    }, [legendStats, legendId, type, upgrade, degrade, handleUpgrade]);

    return (
        <div className="stat">
            <Popover content={capitalize(type)} placement="right">
                <img
                    onClick={handleUpgrade}
                    src={icon}
                    alt={`${type} icon`}
                    className={upgrade ? 'upgrade' : ''}
                    style={{ width: size, height: size, borderRadius: '50%' }}
                />
            </Popover>
            {Array.from({ length: 10 }, (_, i) => (
                <div key={i} className={setClassNames(i)} />
            ))}
        </div>
    );
};
