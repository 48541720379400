import React, {useState} from "react";
import {message, Tag, Typography, Input, Spin} from "antd";
import {useNavigate} from "react-router-dom";
import {RecentlySearchedPlayer} from "../shared/types/local-storage.type";
import {getSteamDataBySteamId64} from "../shared/services/brawlhalla.service";
import {useLocalStorage} from "@mralbelo/react-hooks";
import {useDispatch} from "react-redux";
import {setLoading} from "../shared/store/reducers/loading.reducer";

const {Text} = Typography;
const {Search} = Input;

export default function PlayerStatsPage() {
    const [searching, setSearching] = useState(false);
    const [recentPlayers] = useLocalStorage<{ [key: number]: RecentlySearchedPlayer }>(
        "recently-searched-players", {}
    );
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();

    const isValidSteamId64 = (steamID64: string) => /^[0-9]{17}$/.test(steamID64);

    async function onSearch(steamID64: string) {
        const trimmedSteamID64 = steamID64.trim();
        if (!trimmedSteamID64 || searching || !isValidSteamId64(trimmedSteamID64)) {
            messageApi.error({content: "Please enter a valid 17-digit SteamID64", duration: 2.5});
            return;
        }

        const messageKey = "player-search";
        messageApi.loading({key: messageKey, content: "Searching for player..."});
        setSearching(true);
        dispatch(setLoading(true));

        try {
            const data = await getSteamDataBySteamId64(trimmedSteamID64);
            navigate(data.brawlhalla_id.toString());
        } catch (error) {
            messageApi.error({
                key: messageKey,
                content: "Failed to retrieve data. Please try again later.",
                duration: 2.5
            });
        } finally {
            messageApi.destroy(messageKey);
            setSearching(false);
            dispatch(setLoading(false));
        }
    }

    return (
        <>
            {contextHolder}
            {
                (searching) ?
                    <div style={{textAlign: 'center'}}><Spin style={{margin: "10px 0"}}/></div>:
                    <>
                        <Search
                            style={{marginTop: 25}}
                            type="number"
                            addonBefore="https://steamcommunity.com/profiles/"
                            placeholder="steamID64"
                            onSearch={onSearch}
                            allowClear
                            disabled={searching}
                            aria-label="Search by SteamID64"
                        />
                        {Object.keys(recentPlayers).length && (
                            <div style={{marginTop: 25}}>
                                <Text style={{display: 'block'}}>Recently Searched:</Text>
                                {Object.values(recentPlayers).map(player => (
                                    <Tag
                                        key={player.brawlhalla_id}
                                        style={{cursor: 'pointer'}}
                                        onClick={() => navigate(player.brawlhalla_id.toString())}
                                        aria-label={`Navigate to ${player.name}'s stats`}
                                    >
                                        {player.name}
                                    </Tag>
                                ))}
                            </div>
                        )}
                    </>
            }
        </>
    );
}
