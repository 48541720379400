import axios from "axios";
import {BrawlhallaResponse, Legend, PlayerRanked, PlayerStats, SteamData} from "../types/legends.type";

// Create an axios instance configured for the Brawlhalla API.
const brawlhalla = axios.create({
    baseURL: process.env.BRAWLHALLA_API_BASEURL || 'https://brawlhalla.fly.dev/v1',
    timeout: 8000,
});

/**
 * Retrieves all legends from the Brawlhalla API.
 * @returns {Promise<Legend[]>} A promise that resolves to an array of Legend objects.
 */
export async function getAllLegends(): Promise<Legend[]> {
    try {
        const response = await brawlhalla.get<BrawlhallaResponse<Legend[]>>('/legends/all');
        return response.data.data.map(legend => ({
            ...legend,
            strength: +legend.strength,
            dexterity: +legend.dexterity,
            defense: +legend.defense,
            speed: +legend.speed,
        }));
    } catch (error) {
        console.error("Failed to fetch all legends:", error);
        throw error;
    }
}

/**
 * Retrieves a legend by name.
 * @param {string} legendName - The name of the legend to retrieve.
 * @returns {Promise<Legend>} A promise that resolves to a Legend object.
 */
export async function getLegendByName(legendName: string): Promise<Legend> {
    try {
        const response = await brawlhalla.get<BrawlhallaResponse<Legend>>(`/legends/name?legend_name=${legendName}`);
        return response.data.data;
    } catch (error) {
        console.error(`Failed to fetch legend by name: ${legendName}`, error);
        throw error;
    }
}

/**
 * Retrieves ranked data for a player by their Brawlhalla ID.
 * @param {number} BrawlhallaId - The Brawlhalla ID of the player.
 * @returns {Promise<PlayerRanked>} A promise that resolves to the PlayerRanked data.
 */
export async function getRankedById(BrawlhallaId: number): Promise<PlayerRanked> {
    try {
        const response = await brawlhalla.get<BrawlhallaResponse<PlayerRanked>>(`ranked/id?brawlhalla_id=${BrawlhallaId}`);
        return response.data.data;
    } catch (error) {
        console.error(`Failed to fetch ranked data by ID: ${BrawlhallaId}`, error);
        throw error;
    }
}

/**
 * Retrieves Steam data for a player by their Steam ID 64-bit number.
 * @param {string} steamID64 - The Steam ID 64-bit number of the player.
 * @returns {Promise<SteamData>} A promise that resolves to the player's Steam data.
 */
export async function getSteamDataBySteamId64(steamID64: string): Promise<SteamData> {
    try {
        const response = await brawlhalla.get<BrawlhallaResponse<SteamData>>(`steamdata/id?steam_id=${steamID64}`);
        return response.data.data;
    } catch (error) {
        console.error(`Failed to fetch Steam data by SteamID64: ${steamID64}`, error);
        throw error;
    }
}

/**
 * Retrieves player statistics by their Brawlhalla ID.
 * @param {number} BrawlhallaId - The Brawlhalla ID of the player.
 * @returns {Promise<PlayerStats>} A promise that resolves to the PlayerStats data.
 */
export async function getPlayerStatsById(BrawlhallaId: number): Promise<PlayerStats> {
    try {
        const response = await brawlhalla.get<BrawlhallaResponse<PlayerStats>>(`stats/id?brawlhalla_id=${BrawlhallaId}`);
        return response.data.data;
    } catch (error) {
        console.error(`Failed to fetch player stats by ID: ${BrawlhallaId}`, error);
        throw error;
    }
}
