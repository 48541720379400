import React, { useMemo } from "react";
import { Breadcrumb, Card, Typography } from "antd";
import { useParams, Link } from "react-router-dom";
import { useDocumentTitle } from "@mralbelo/react-hooks";
import UserStat from "../shared/components/UserStat";
import usePlayerData from "../shared/hooks/usePlayerData";

export default function PlayerStatsDetailsPage() {
    const { brawlhallaId } = useParams<{ brawlhallaId?: string }>();
    const { ranked, stats, loading, error } = usePlayerData(brawlhallaId);
    const rankedFormatted = useMemo(() => ranked && ({
        winLossRatio: ranked.games === 0 ? '0%' : `${Number(ranked.wins / ranked.games * 100).toFixed(2)}%`
    }), [ranked]);
    const statsFormatted = useMemo(() => stats && ({
        winLossRatio: `${Number(stats.wins / stats.games * 100).toFixed(2)}%`,
        playtime: `${(stats.legends.reduce((acc, curr) => acc + curr.matchtime, 0) / 3600).toFixed(0)}hr`,
        legendKills: stats.legends.reduce((acc, curr) => acc + curr.kos, 0),
        legendDeaths: stats.legends.reduce((acc, curr) => acc + curr.falls, 0)
    }), [stats]);
    useDocumentTitle(stats ? `${stats.name}'s Brawlhalla Stats` : "Brawlhalla Stats", false, [stats]);

    if (loading) return <></>;
    if (error || !stats || !ranked || !statsFormatted || !rankedFormatted) return <Typography>Error fetching data or data not available.</Typography>;

    return (
        <>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item><Link to="/playerstats">Player Stats</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{stats.name}</Breadcrumb.Item>
            </Breadcrumb>

            <Typography.Title style={{ marginTop: 0 }}>{stats.name}</Typography.Title>

            <Card title="Lifetime Overview" style={{ marginBottom: 25 }}>
                <UserStat label="Level" value={stats.level.toString()} />
                <UserStat label="Matches" value={stats.games.toString()} />
                <UserStat label="Playtime" value={statsFormatted.playtime} />
                <UserStat label="Wins" value={stats.wins.toString()} />
                <UserStat label="Winrate" value={statsFormatted.winLossRatio} />
                <UserStat label="Kills" value={statsFormatted.legendKills.toString()} />
                <UserStat label="Deaths" value={statsFormatted.legendDeaths.toString()} />
                <UserStat label="K/D Ratio" value={(statsFormatted.legendKills / statsFormatted.legendDeaths).toFixed(2)} />
            </Card>

            <Card title="Rank Overview">
                <UserStat label="Peak Rating" value={ranked.peak_rating.toString()} />
                <UserStat label="Matches" value={ranked.games.toString()} />
                <UserStat label="Win" value={ranked.wins.toString()} />
                <UserStat label="Winrate" value={rankedFormatted.winLossRatio} />
            </Card>
        </>
    );
}
