import {Avatar, Popover} from "antd";
import React from "react";
import {Legend} from "../types/legends.type";

interface LegendProps {
    legend: Legend
    onClick?: () => void,
    size?: number
}

export default function LegendComponent(props: LegendProps) {
    const {legend, onClick, size } = props;

    return (
        <Popover
            key={`legend-id-${legend.legend_id}`}
            content={<p>{legend.weapon_one}, {legend.weapon_two}</p>}
            open={onClick ? undefined : false }
            title={legend.bio_name}>
            <Avatar className="legend" onClick={onClick} legend-id={legend.legend_id} size={size || 80} alt={legend.bio_name} src={legend.thumbnail}>
                {legend.bio_name}
            </Avatar>
        </Popover>);
}
