import React, { useMemo } from "react";
import { Popover } from "antd";
import unknownWeapon from '../images/weapons/unknown_Icon.png';
import axe from '../images/weapons/Axe_Icon.webp';
import boots from '../images/weapons/Battle_Boots_Icon.webp';
import pistol from '../images/weapons/Blasters_Icon.webp';
import bow from '../images/weapons/Bow_Icon.webp';
import cannon from '../images/weapons/Cannon_Icon.webp';
import gauntlets from '../images/weapons/Gauntlets_Icon.webp';
import hammer from '../images/weapons/Grapple_Hammer_Icon.webp';
import greatsword from '../images/weapons/Greatsword_Icon.webp';
import katars from '../images/weapons/Katars_Icon.webp';
import orb from '../images/weapons/Orb_Icon.webp';
import rocketlance from '../images/weapons/Rocket_Lance_Icon.webp';
import scythe from '../images/weapons/Scythe_Icon.webp';
import spear from '../images/weapons/Spear_Icon.webp';
import sword from '../images/weapons/Sword_Icon.webp';

interface WeaponProps {
    weapon: string;
    style?: React.CSSProperties;
}

const weaponImages: Record<string, string> = {
    axe, boots, pistol, bow, cannon, fists: gauntlets, hammer,
    greatsword, katar: katars, orb, rocketlance, scythe, spear, sword
};

export default function Weapon({ weapon, style }: WeaponProps) {
    const imageSrc = useMemo(() => weaponImages[weapon.toLowerCase()] || unknownWeapon, [weapon]);

    return (
        <Popover content={weapon} key={`weapon-${weapon}`}>
            <img style={style} src={imageSrc} alt={weapon} />
        </Popover>
    );
}
