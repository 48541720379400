import React, {useCallback, useEffect} from "react";
import { getAllLegends } from "../shared/services/brawlhalla.service";
import { useDispatch } from "react-redux";
import { setFinishedLoading, setLegends } from "../shared/store/reducers/main.reducer";
import { Image, Spin, message } from "antd";
import logo from "../shared/images/brawlhalla-logo.png";
import { LoadingOutlined } from '@ant-design/icons';
import {Legend} from "../shared/types/legends.type";
import {useLocalStorage} from "@mralbelo/react-hooks";

export default function LoadingPage() {
    const dispatch = useDispatch();
    const [legends, setLegendsCache] = useLocalStorage<Legend[] | null>('legendsData', null, 3600000);
    const [messageApi, contextHolder] = message.useMessage();

    const fetchLegends = useCallback(async () => {
        if (legends) {
            dispatch(setLegends(legends));
            setTimeout(() => dispatch(setFinishedLoading(true)), 500);
        } else {
            try {
                const legendsData = await getAllLegends();
                setLegendsCache(legendsData);
                dispatch(setLegends(legendsData));
                dispatch(setFinishedLoading(true));
            } catch (error) {
                console.error(error);
                messageApi.open({
                    type: 'error',
                    content: 'Failed to load data. Please refresh the page.',
                }).then();
            }
        }
    }, [dispatch, legends, messageApi, setLegendsCache]);

    useEffect(() => {
        fetchLegends().then();
    }, [fetchLegends]);

    return(
        <div id="loading" role="alert" aria-busy="true">
            {contextHolder} {/* Render the context holder which connects the message API to the component */}
            <Image src={logo} preview={false} alt="Brawlhalla Logo" />
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />} />
        </div>
    );
}
