import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useSelector} from 'react-redux';
import mainReducer from "./reducers/main.reducer";
import loadingReducer from "./reducers/loading.reducer";

export const store = configureStore({
    reducer: {
        main: mainReducer,
        loading: loadingReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export const useStoreSelector: TypedUseSelectorHook<RootState> = useSelector;
